/* eslint-disable no-void */
import './focus_wrap'

let current = null

const findPopover = function (target) {
  let id
  if ((id = target != null ? target.id : void 0)) {
    return document.querySelector(`[aria-labelledby='${id}']`)
  }
}

const show = function (target) {
  let popover
  if (current) {
    hide(current)
  }
  current = target
  target.classList.add('popover-active')
  if (!(popover = findPopover(target))) {
    return
  }
  popover.hidden = false
  if (target.dataset.popoverPlacement === 'up') {
    popover.classList.add('is-up')
    popover.style.top = '-' + Math.ceil(popover.offsetHeight) + 'px'
    popover.style.left =
      Math.ceil(
        target.offsetLeft - popover.offsetWidth / 2 + target.offsetWidth / 2
      ) + 'px'
  } else if (target.dataset.popoverPlacement === 'up-left') {
    popover.classList.add('is-up')
    popover.style.top = '-' + Math.ceil(popover.offsetHeight) + 'px'
    popover.style.left =
      Math.ceil(target.offsetLeft / 2 + target.offsetWidth / 2) + 'px'
  } else if (target.dataset.popoverPlacement === 'up-right') {
    popover.classList.add('is-up')
    popover.style.top = '-' + Math.ceil(popover.offsetHeight) + 'px'
    popover.style.left =
      -Math.ceil(target.offsetLeft / 2 + target.offsetWidth / 2) + 'px'
  } else if (target.dataset.popoverPlacement === 'right') {
    popover.classList.add('is-right')
    popover.style.top = Math.ceil(target.offsetTop) + 'px'
    popover.style.left =
      Math.ceil(target.offsetWidth + target.offsetLeft) + 'px'
  } else {
    popover.style.top = Math.ceil(target.offsetHeight + target.offsetTop) + 'px'
    popover.style.left =
      Math.ceil(
        target.offsetLeft - popover.offsetWidth / 2 + target.offsetWidth / 2
      ) + 'px'
  }
  popover.classList.add('is-visible')
  popover.setAttribute('aria-hidden', false)
  if (popover.classList.contains('dropdown')) {
    target.setAttribute('aria-expanded', true)
  }
  return (popover.dataset.focusWrap = true)
}

const hide = function () {
  let popover
  if (current == null) {
    return
  }
  current.classList.remove('popover-active')
  if (!(popover = findPopover(current))) {
    return
  }
  popover.classList.remove('is-visible')
  popover.setAttribute('aria-hidden', true)
  if (popover.classList.contains('dropdown')) {
    current.setAttribute('aria-expanded', false)
  }
  return (current = null)
}

document.addEventListener('click', function (event) {
  let closest, target
  ;({ target } = event)
  if (current != null ? current.contains(target) : void 0) {
    return hide()
  } else if (findPopover(current)?.contains(target)) {
    if (target.closest('button') != null) {
      return hide()
    }
  } else if ((closest = target.closest('[data-popover]'))) {
    event.preventDefault()
    if (!(closest.readOnly || closest.disabled)) {
      return show(closest)
    }
  } else {
    return hide()
  }
})

document.addEventListener('keydown', function ({ key }) {
  if (key !== 'Esc' && key !== 'Escape') {
    return
  }
  if (current != null) {
    current.focus()
  }
  return hide()
})

export default { show, hide }
